import React from "react";

const PageNotFound = () => (
  <div>

    <div>

      <h1>Page not found</h1>

    </div>

  </div>
)

export default PageNotFound